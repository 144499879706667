import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import InboxIcon from '@mui/icons-material/MoveToInbox';
import UpdateIcon from '@mui/icons-material/Update';
import MenuIcon from '@mui/icons-material/Menu';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { profileEntity } from "../bloc/gapi";

const drawerWidth = 200;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(5)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(6)} + 1px)`,
    },
});


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export default function LeftDrawer() {
    const profile = profileEntity.use();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const navigate = useNavigate()


    const handleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <Drawer variant="permanent" color="primary" open={drawerOpen}>
            <div style={{ padding: "34px" }} />
            <IconButton onClick={handleDrawer}>
                <MenuIcon />
            </IconButton>
            <div style={{ padding: "4px" }} />
            <Divider />
            <List>
                <ListItem key={'Envanter'} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: drawerOpen ? 'initial' : 'center',
                            px: 2.5,
                        }}
                        disabled={!profile}
                        onClick={() => { navigate('/envanter') }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: drawerOpen ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Envanter'} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'Task'} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: drawerOpen ? 'initial' : 'center',
                            px: 2.5,
                        }}
                        disabled={!profile}
                        onClick={() => { navigate('/task') }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: drawerOpen ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <TaskAltIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Task'} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Tablolar'} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: drawerOpen ? 'initial' : 'center',
                            px: 2.5,
                        }}
                        disabled={!profile}
                        onClick={() => { navigate('/sheet') }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: drawerOpen ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <FilePresentIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Tablolar'} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Drive'} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: drawerOpen ? 'initial' : 'center',
                            px: 2.5,
                        }}
                        disabled={true}
                        onClick={() => { navigate('/comingSoon') }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: drawerOpen ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <UpdateIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Drive'} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'Emailler'} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: drawerOpen ? 'initial' : 'center',
                            px: 2.5,
                        }}
                        disabled={true}
                        onClick={() => { navigate('/comingSoon') }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: drawerOpen ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <UpdateIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Emailler'} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Takvim'} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: drawerOpen ? 'initial' : 'center',
                            px: 2.5,
                        }}
                        disabled={true}
                        onClick={() => { navigate('/comingSoon') }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: drawerOpen ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <UpdateIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Takvim'} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            </List>
            <div style={{ padding: "4px" }} />
        </Drawer>
    )
}
