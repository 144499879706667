import { Button, Card, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { profileEntity } from "../bloc/gapi";

export default function Home() {
    const navigate = useNavigate()
    const profile = profileEntity.use();

    return (
        <>
            <Card sx={{ p: 20, m: 20 }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column" }}>
                    <div>
                        <Typography variant="h2" gutterBottom>
                            Mahir Atolye
                        </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                        <List>
                            <ListItem>
                                <Button variant="contained" onClick={() => { navigate('/envanter') }} disabled={!profile}>
                                    Envanter Sistemi
                                </Button>
                            </ListItem>
                            <ListItem>
                                <Button variant="contained" onClick={() => { navigate('/task') }} disabled={!profile}>
                                    Task Sistemi
                                </Button>
                            </ListItem>
                            <ListItem>
                                <Button variant="contained" onClick={() => { navigate('/sheet') }} disabled={!profile}>
                                    Tablo Sistemi
                                </Button>
                            </ListItem>
                        </List>
                    </div>
                </div>
            </Card>
        </>
    )
}
