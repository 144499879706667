import { Button, Card, Input, Typography } from "@mui/material";
import { addItem } from "../bloc/items";


export default function AddItemCard() {
    return (
        <Card style={{ padding: "40px", margin: "35px", position: "fixed", bottom: "0", right: "0", border: "1px solid black" }}>
        <Typography variant="h5" component="div" gutterBottom bold>
          Ekipman Ekle
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            addItem(e.target.name.value, e.target.description.value);
            e.target.name.value = "";
            e.target.description.value = "";
          }}
        >
          <Input type="text" name="name" placeholder="Ekipman Adı" />
          <span style={{ padding: "10px" }} />
          <Input type="text" name="description" placeholder="Açıklama" />
          <div style={{ padding: "10px" }} />
          <Input type="text" name="location" placeholder="Yer" />
          <span style={{ padding: "10px" }} />
          <Button variant="contained" type="submit">Ekle</Button>
        </form>
      </Card>
    )
}