import { entity } from 'simpler-state'


export const ItemEntity = entity([]);

export const getItems = () => {
    fetch("https://fastapi48.deta.dev/items/")
        .then((res) => res.json())
        .then((data) => {
            ItemEntity.set(data);
        }
        );
}

export const addItem = (name, dsc) => {
    fetch("https://fastapi48.deta.dev/items/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            name: name,
            description: dsc,
        }),
    })
        .then((res) => res.json())
        .then((data) => {
            getItems();
        });
}

export const deleteItem = (id) => {
    fetch(`https://fastapi48.deta.dev/items/${id}`, {
        method: "DELETE",
    })
        .then((res) => res.json())
        .then((data) => {
            getItems();
        });
}

export const searchItem = (name) => {
    fetch(`https://fastapi48.deta.dev/items/`)
        .then((res) => res.json())
        .then((data) => {
            ItemEntity.set(data.filter((item) => item.name.toLowerCase().includes(name.toLowerCase())));
        });
}

export const updateItem = (item) => {
    fetch(`https://fastapi48.deta.dev/items/${item.item_id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(item)
    })
        .then((res) => res.json())
        .then((data) => {
            getItems();
        });
}