import React, { useEffect } from "react";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Fab, Input, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import AddItemCard from "../companents/AddItemCard";
import { TasksEntity, addTasks, deleteTasks, getTasks, updateTasks } from "../bloc/tasks";


export default function TaskApp() {
    const Tasks = TasksEntity.use();
    const [edit, setEdit] = React.useState(-1);
    const [editTask, setEditTask] = React.useState({});
    const [adding, setAdding] = React.useState(false);

    const handleAdding = () => { setAdding((prev) => !prev) };


    useEffect(() => { getTasks() }, []);

    return (
        <>
            {Tasks &&
                Tasks.map((task) => (
                    <div key={task.task_id}>
                        <Card style={{ padding: "28px", margin: "20px" }}>
                            {edit === task.task_id ?
                                (
                                    <>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h4" gutterBottom>
                                                <Input type="text" name="name" placeholder="Ekipman Adı" defaultValue={task.name} onChange={(e) => {
                                                    setEditTask({ ...editTask, name: e.target.value });
                                                }} />
                                            </Typography>
                                        </div>
                                        <Input type="text" name="description" placeholder="Açıklama" defaultValue={task.description} onChange={(e) => {
                                            setEditTask({ ...editTask, description: e.target.value });
                                        }} />
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button variant="contained" onClick={() => deleteTasks(task.task_id)}
                                                style={{ backgroundColor: "red", color: "white" }}
                                            >
                                                <DeleteIcon>delete</DeleteIcon>
                                            </Button>
                                            <span style={{ padding: "5px" }} />
                                            <Button variant="contained" onClick={() => {
                                                setEdit(-1);
                                                updateTasks(editTask);
                                            }}>
                                                <DoneIcon>done</DoneIcon>
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h4" gutterBottom>
                                                {task.name}
                                            </Typography>
                                        </div>
                                        <Typography variant="body2" gutterBottom>
                                            {task.description}
                                        </Typography>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button variant="contained" onClick={() => deleteTasks(task.task_id)}
                                                style={{ backgroundColor: "red", color: "white" }}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                            <span style={{ padding: "5px" }} />
                                            <Button variant="contained" onClick={() => {
                                                setEdit(task.task_id);
                                                setEditTask(task);
                                            }}>
                                                <EditIcon />
                                            </Button>
                                        </div>
                                    </>
                                )
                            }
                        </Card>
                    </div>
                ))}
            {adding ? (
                <>
                    <Fab color="primary" aria-label="add" onClick={handleAdding} style={{ position: "fixed", bottom: "0", right: "0", margin: "15px" }}>
                        <CloseIcon />
                    </Fab>
                    <AddItemCard />
                </>
            ) : (
                <Fab color="primary" aria-label="add" onClick={handleAdding} style={{ position: "fixed", bottom: "0", right: "0", margin: "15px" }}>
                    <AddIcon />
                </Fab>
            )}
        </>
    )
}
