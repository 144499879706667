import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        white: {
            main: "#fff",
        },
        primary: {
            main: "#1976d2",
        },
        secondary: {
            main: "#5fa4ff",
        },
        error: {
            main: "#dc004e",
        },
        background: {
            main: "#061f38",
        },
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#5fa4ff",
                },
            },
        },
    },
    typography: {
        h1: {
            fontSize: "1.6rem",
            fontWeight: 600,
            color: "#fff",
            letterSpacing: "0.5px",
            textTransform: "capitalize",
        },
    },
});