import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { searchItem } from '../bloc/items';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';
import { profileEntity, setProfile } from '../bloc/gapi';
import { IconButton, Tooltip } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export default function Appbar() {
    const profile = profileEntity.use()
    const navigate = useNavigate()

    const client = '45763344264-kh0vidbo21f4ivmfrf91v5k0gcqd6kop.apps.googleusercontent.com'
    const supportedMails = [
        'm.bilal.alpaslan@gmail.com',
        'mahir.teknoloji@gmail.com',
    ]

    const successGoogle = (response) => {
        if (supportedMails.includes(response.profileObj.email)) {
            setProfile(response.profileObj);
        } else {
            alert('Bu sistem sadece Mahir Atölye çalışanlarına açıktır.')
            console.log(response);
        }

    }

    const failureGoogle = (response) => {
        console.log(response);
    }

    const logout = () => {
        setProfile(null);
    }

    React.useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: client,
                scope: 'https://www.googleapis.com/auth/spreadsheets'
            });
        };
        gapi.load('client:auth2', initClient);
    });

    return (
        <MuiAppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                <HomeIcon
                    sx={{ mr: 2, cursor: "pointer" }}
                    onClick={() => { navigate('/') }}
                />
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    MTT Envanter Sistemi
                </Typography>
                {profile ?
                    <>
                    <Tooltip title={profile.name}>
                        <img
                            src={profile.imageUrl}
                            alt={profile.name}
                            style={{ width: 45, height: 45, borderRadius: 25, marginRight: 20 }}
                            tooltip={profile.name}
                        />
                    </Tooltip>
                    <GoogleLogout
                        clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                        buttonText="Logout"
                        onLogoutSuccess={logout}
                    ></GoogleLogout>
                        {/* <IconButton
                            size="large"
                            color="inherit"
                            onClick={() => {
                                gapi.auth2.getAuthInstance().signOut(); 
                                setProfile(null);
                            }}
                        >
                            <ExitToAppIcon />
                        </IconButton> */}
                    </>
                    :
                    <GoogleLogin
                        clientId={client}
                        buttonText="Login"
                        onSuccess={successGoogle}
                        onFailure={failureGoogle}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={true}
                    />
                }
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Ara..."
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(e) => searchItem(e.target.value)}
                    />
                </Search>
            </Toolbar>
        </MuiAppBar>
    )
}