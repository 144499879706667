import React from "react";
import EnvanterApp from "./pages/Envanter";
import { Box, CssBaseline } from "@mui/material";
import Appbar from "./companents/Appbar";
import LeftDrawer from "./companents/Drawer";
import { Routes, Route, Navigate } from "react-router-dom";
import TaskApp from "./pages/Task";
import Home from "./pages/Home";
import Sheet from "./pages/Sheet";
import { profileEntity } from "./bloc/gapi";


export default function App() {
  const profile = profileEntity.use();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Appbar />
      <LeftDrawer/>

      <Box component="main" style={{ width: "100%", height: "100%", marginLeft:"0px", marginTop:"55px" }}>        
        <Routes>
          <Route path="/" element={<Home />} />
          {profile && <>
            <Route path="/envanter" element={<EnvanterApp />} />
            <Route path="/task" element={<TaskApp />} />
            <Route path="/sheet" element={<Sheet />} />
            </>
          }
          <Route path="*" element={<Navigate to="/" />} /> 
        </Routes>
      </Box>
    </Box>
  )
}
