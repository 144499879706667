import { entity } from 'simpler-state'


export const TasksEntity = entity([]);

export const getTasks = () => {
    fetch("https://fastapi48.deta.dev/tasks/")
        .then((res) => res.json())
        .then((data) => {
            TasksEntity.set(data);
        }
        );
}

export const addTasks = (name, dsc) => {
    fetch("https://fastapi48.deta.dev/tasks/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            name: name,
            description: dsc,
        }),
    })
        .then((res) => res.json())
        .then((data) => {
            getTasks();
        });
}

export const deleteTasks = (id) => {
    fetch(`https://fastapi48.deta.dev/tasks/${id}`, {
        method: "DELETE",
    })
        .then((res) => res.json())
        .then((data) => {
            getTasks();
        });
}

export const searchTasks = (name) => {
    fetch(`https://fastapi48.deta.dev/tasks/`)
        .then((res) => res.json())
        .then((data) => {
            TasksEntity.set(data.filter((task) => task.name.toLowerCase().includes(name.toLowerCase())));
        });
}

export const updateTasks = (task) => {
    fetch(`https://fastapi48.deta.dev/tasks/${task.item_id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(task)
    })
        .then((res) => res.json())
        .then((data) => {
            getTasks();
        });
}