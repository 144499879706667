import React, { useEffect } from "react";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Fab, Input, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import AddItemCard from "../companents/AddItemCard";
import { ItemEntity, getItems, deleteItem, updateItem } from "../bloc/items";


export default function EnvanterApp() {
    const Items = ItemEntity.use();
    const [edit, setEdit] = React.useState(-1);
    const [editItem, setEditItem] = React.useState({});
    const [adding, setAdding] = React.useState(false);

    const handleAdding = () => { setAdding((prev) => !prev) };


    useEffect(() => { getItems() }, []);

    return (
        <>
            {Items &&
                Items.map((item) => (
                    <div key={item.item_id}>
                        <Card style={{ padding: "28px", margin: "20px" }}>
                            {edit === item.item_id ?
                                (
                                    <>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h4" gutterBottom>
                                                <Input type="text" name="name" placeholder="Ekipman Adı" defaultValue={item.name} onChange={(e) => {
                                                    setEditItem({ ...editItem, name: e.target.value });
                                                }} />
                                            </Typography>
                                            <Typography variant="subtitle1" gutterBottom>
                                                <Input type="text" name="location" placeholder="Yer" defaultValue={item.location} onChange={(e) => {
                                                    setEditItem({ ...editItem, location: e.target.value });
                                                }} />
                                            </Typography>
                                        </div>
                                        <Input type="text" name="description" placeholder="Açıklama" defaultValue={item.description} onChange={(e) => {
                                            setEditItem({ ...editItem, description: e.target.value });
                                        }} />
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button variant="contained" onClick={() => deleteItem(item.item_id)}
                                                style={{ backgroundColor: "red", color: "white" }}
                                            >
                                                <DeleteIcon>delete</DeleteIcon>
                                            </Button>
                                            <span style={{ padding: "5px" }} />
                                            <Button variant="contained" onClick={() => {
                                                setEdit(-1);
                                                updateItem(editItem);
                                            }}>
                                                <DoneIcon>done</DoneIcon>
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h4" gutterBottom>
                                                {item.name}
                                            </Typography>
                                            <Typography variant="subtitle1" gutterBottom>
                                                {item.location}
                                            </Typography>
                                        </div>
                                        <Typography variant="body2" gutterBottom>
                                            {item.description}
                                        </Typography>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button variant="contained" onClick={() => deleteItem(item.item_id)}
                                                style={{ backgroundColor: "red", color: "white" }}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                            <span style={{ padding: "5px" }} />
                                            <Button variant="contained" onClick={() => {
                                                setEdit(item.item_id);
                                                setEditItem(item);
                                            }}>
                                                <EditIcon />
                                            </Button>
                                        </div>
                                    </>
                                )
                            }
                        </Card>
                    </div>
                ))}
            {adding ? (
                <>
                    <Fab color="primary" aria-label="add" onClick={handleAdding} style={{ position: "fixed", bottom: "0", right: "0", margin: "15px" }}>
                        <CloseIcon />
                    </Fab>
                    <AddItemCard />
                </>
            ) : (
                <Fab color="primary" aria-label="add" onClick={handleAdding} style={{ position: "fixed", bottom: "0", right: "0", margin: "15px" }}>
                    <AddIcon />
                </Fab>
            )}
        </>
    )
}
