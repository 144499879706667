import { Button, Card, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { profileEntity } from "../bloc/gapi";


export default function Sheet() {
    const profile = profileEntity.use();

    return (
        <iframe 
        src="https://docs.google.com/spreadsheets/d/1VS8i1D1c8tIVKegHEBa1yRhB-pmn2Byvg499f1TxA6s/edit?usp=sharing&gid=0"
        title="Google Sheet"
        width="100%"
        min-height="500px"
        height={window.innerHeight - 55}
        margin="0px"
        sandbox="allow-scripts allow-same-origin allow-popups allow-forms allow-modals allow-popups-to-escape-sandbox"        
        ></iframe>
    )
}
